import * as React from "react"

import Layout from "../components/layout"
import { PPEButton } from "../components/PPEButton"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <div style={{ width: '100%', paddingTop: '200px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <h1>404: Not Found</h1>
    <p>Please return to the home page.</p>
    <PPEButton centered link={'/'} buttonText={"Home"} />
  </div>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
